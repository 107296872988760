import React, { useState } from 'react'

import Pay from '@/components/pay'
import Privacy from '@/components/Privacy'

function OrderPay () {
	const [isPay, setIsPay] = useState(true)
	return (
		<React.Fragment>
            <Pay isPay={ isPay } switchPage={() => setIsPay(false) } />
            <Privacy isPay={ isPay } switchPage={() => setIsPay(true) }/>
		</React.Fragment>
	)
}

export default OrderPay
