/*
 * @Author: heyouqin@moyi365.com
 * @LastEditors: heyouqin@moyi365.com
 * @Date: 2022-08-08 17:19:45
 * @LastEditTime: 2023-08-28 16:09:25
 * @Descripttion: file content
 */

import { PRODUCT } from './typings/apiType'
import { getParams } from './utils/tools'
// appid 为公众号 appid
const conf = {
  changchun: {
    name: 'XFAI助手',
    appid: 'wxfb25ff189be24c5a',
    product: 'changchun',
    theme: '#00B9EF',
    version: '2.7.2'
  },
  bingo: {
    name: '易辅来',
    appid: 'wx6f869837dd63d26e',
    product: 'bingo',
    theme: '#3F74FE',
    version: '2.7.2'
  },
  jmespeak: {
    name: '君铭易听说',
    appid: 'wxb27a015bf4c59a58',
    product: 'jmespeak',
    theme: '#6077FF',
    version: '2.7.2'
  },
  ebook: {
    name: '易辅来图书版',
    appid: 'wx6f869837dd63d26e',
    product: 'ebook',
    theme: '#3F74FE',
    version: '2.7.2'
  },
  lingyu: {
    name: '灵语通',
    appid: 'wx5bfac127cc8cfce3',
    product: 'lingyu',
    theme: '#00B9EF',
    version: '2.9.8'
  }
}

interface ConfRule {
  name: string
  appid: string
  product: PRODUCT
  theme: string
  version: string
}

export const product = getParams(
  'product',
  getParams('state') || (localStorage.getItem('state') as string)
) as PRODUCT

export const getConf = (): ConfRule => {
  return conf[product]
}
