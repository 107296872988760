/*
 * @Author: heyouqin@moyi365.com
 * @LastEditors: heyouqin@moyi365.com
 * @Date: 2022-07-20 09:52:09
 * @LastEditTime: 2023-08-24 15:15:15
 * @Descripttion: file content
 */
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import './utils/flexible'
// import VConsole from 'vconsole'
import { getConf } from './proConf'
/* eslint-disable no-new */
// new VConsole()
// 注入主题色
document.documentElement.style.setProperty('--theme', getConf().theme)
document.title = getConf().name
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />)
window.alert = function (name) {
    const iframe: any = document.createElement('IFRAME')
    iframe.style.display = 'none'
    iframe.setAttribute('src', 'data:text/plain,')
    document.documentElement.appendChild(iframe)
    window.frames[0].window.alert(name)
    iframe.parentNode.removeChild(iframe)
}
