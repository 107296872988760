import React from 'react'

import './index.scss'

export interface BtnList {
	/**
	 * 行为，jump：跳转行为；cancel：取消行为l；confirm：确认行为
	 */
	action: string

	/**
	 * 跳转时才需要该数据, 因后端传输问题，定义为 json 字符串 {"data":{},"router":""}
	 */
	jump_data?: string

	/**
	 * 按钮文本
	 */
	text: string
	[property: string]: any
}

export interface ToastProps {
  title?: string
  subTitle?: string
  cancelText?: string
  confirmText?: string
  btn_list: BtnList[]
  confirmCallback?: ()=> void
  cancelCallback?: ()=> void
}

export default function Toast ({ title, subTitle, cancelText, confirmText, confirmCallback, cancelCallback }: ToastProps) {
  const cancel = () => {
    cancelCallback && cancelCallback()
  }
  const confirm = () => {
    confirmCallback && confirmCallback()
  }
  return (
    <div className="dialog-div">
      <div className="dialog">
        {title && <div className="dialog-title">{title}</div>}
        {subTitle && <div className="dialog-content">{subTitle}</div>}
        <div className='dialog-btn' style={{ display: 'flex' }}>
          {cancelText && (
            <button className="dialog-restartButton" onClick={cancel}>
              {cancelText}
            </button>
          )}
          {confirmText && (
            <button className="dialog-continueButton" onClick={confirm}>
              {confirmText}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
