import React from 'react'
import { Root, createRoot } from 'react-dom/client'
import Toast, { ToastProps } from '@/components/Toast'

export default function useMessageBox () {
  let div: HTMLDivElement
  let root: Root

  return {
    mount (option: ToastProps) {
      if (document.getElementById('messageBox')) return
      div = document.createElement('div')
      div.setAttribute('id', 'messageBox')
      document.body.appendChild(div)
      root = createRoot(document.getElementById('messageBox') as HTMLElement)
      if (option.btn_list) {
        if (option.btn_list) {
          option.btn_list.forEach(item => {
            option[item.action + 'Text'] = item.text
          })
        }
      }
      const cancel = () => {
        option.cancelCallback && option.cancelCallback()
        this.unmount()
      }
      const confirm = () => {
        option.confirmCallback && option.confirmCallback()
        this.unmount()
      }
      root.render(<Toast {...option} confirmCallback={confirm} cancelCallback={cancel} />)
    },
    unmount () {
      root.unmount()
      document.body.removeChild(div)
    }
  }
}
