/*
 * @Author: heyouqin@moyi365.com
 * @LastEditors: heyouqin@moyi365.com
 * @Date: 2022-07-25 10:25:03
 * @LastEditTime: 2022-08-01 14:13:13
 * @Descripttion: file content
 */
export function getParams (key: string, search?:string) {
	const reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)')
	const r = (search || window.location.search).substr(1).match(reg)
	if (r != null) {
		return decodeURIComponent(r[2])
	}
	return ''
}
