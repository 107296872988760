/*
 * @Author: heyouqin@moyi365.com
 * @LastEditors: heyouqin@moyi365.com
 * @Date: 2022-08-11 09:58:19
 * @LastEditTime: 2022-08-11 10:41:27
 * @Descripttion: file content
 */

const menu = [
	{
		c: 'txt',
		t: 'p',
		d: '欢迎您使用XFAI助手学习应用提供的网络服务。您须先成为XFAI助手学习应用用户之后，才能继续进行收费服务的购买。只有购买收费服务后，才可使用收费服务。在开始购买学习应用收费服务之前，请您首先同意并接受以下服务条款。'
	},
	{
		t: 'p',
		n: [
			{
				t: 'span',
				d: '【注意】XFAI助手有责任提醒您，在购买学习卡前，请您务必仔细阅读、充分理解本协议各条款内容，并自主选择接受与否。您确认接受本服务协议或开始使用本协议项下XFAI助手的服务时，即表示用户与XFAI助手(XFAI助手学习应用)已达成协议关系，并自愿接受并遵守XFAI助手的所有服务协议及相关之子协议、管理规范。：'
			},
			{
				t: 'b',
				d: '如果您未满18周岁，请在法定监护人陪同下阅读本协议。'
			}
		]
	}
]

const note1 = [
	{
		c: 'tit_one',
		t: 'p',
		n: [
			{
				t: 'b',
				d: '一、协议条款的确认和接受'
			}
		]
	},
	{
		c: 'txt',
		t: 'p',
		d: '1、在您决定购买XFAI助手学习卡之前，请仔细阅读本收费协议条款。您应在完全同意以下条款的前提下，才能进行购买学习应用收费服务。XFAI助手将根据以下收费协议条款为您提供收费服务。您在享受XFAI助手收费服务时应严格遵守本协议条款。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '2、XFAI助手根据互联网的发展和中华人民共和国有关法律、法规的变化，不断地完善服务质量并依此修改服务条款。XFAI助手有权就本协议进行更新。您的权利以及义务的表述、购买程序及收费标准，均以最新的服务条款为准。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '3、对于未明确授权给您的XFAI助手付费服务及其他服务的权利、收益由XFAI助手或相关权利人所有'
	},
	{
		c: 'txt',
		t: 'p',
		d: '4、您一旦购买XFAI助手提供的收费服务，即视为您是符合中华人民共和国法律规定的具有完全民事权利能力和完全民事行为能力及能够独立承担民事责任的自然人，并了解及完全同意本平台的《用户服务协议》及本协议的各项内容。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '5、本收费协议中未约定事项以《用户服务协议》中的约定为准。'
	}
]
const note2 = [
	{
		c: 'tit_one',
		t: 'p',
		n: [
			{
				t: 'b',
				d: '二、风险及责任'
			}
		]
	},
	{
		c: 'txt',
		t: 'p',
		d: '1、您应对自身在使用XFAI助手所提供的服务时的一切行为、行动(不论是否故意)负全部责任'
	},
	{
		c: 'txt',
		t: 'p',
		d: '2、您对本服务条款及修改有任何异议，可自动放弃其资格，但不退还任何已继纳的收费服务费用。'
	}
]
const note3 = [
	{
		c: 'tit_one',
		t: 'p',
		n: [
			{
				t: 'b',
				d: '三、收费标准、使用周期及服务权限'
			}
		]
	},
	{
		c: 'txt',
		t: 'p',
		d: '1、一旦您购买了XFAI助手的任何付费学习卡服务，即视为您认可该项服务标明的服务时长、服务范围及金额。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '2、您购买成功后，该项服务即时生效。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '3、您在线使用权限:您的服务使用周期不会因其在使用期间对服务的中断、终止等情况而延长。'
	}
]
const note4 = [
	{
		c: 'tit_one',
		t: 'p',
		n: [
			{
				t: 'b',
				d: '四、购买步骤'
			}
		]
	},
	{
		c: 'txt',
		t: 'p',
		d: '1、对于具体购买操作步骤，XFAI助手会在相关页面上做每一步的明确提示。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '2、一旦您在XFAI助手购买学习卡成功后，则XFAI助手将即时收取该学习卡的费用，请在购买前充分体验，确认购买需求。'
	}
]
const note5 = [
	{
		c: 'tit_one',
		t: 'p',
		n: [
			{
				t: 'b',
				d: '五、用户使用须知'
			}
		]
	},
	{
		c: 'txt',
		t: 'p',
		d: '1、对于您购买的XFAI助手学习卡，您只可以私人使用，不得用干商业、盈利等用途。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '2、您同意不会采用收成免费的方式，全部或部分地在任何公开场合展示您购买的XFAI助手付费服务，除非您的上述行为不会构成侵权。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '3、当您购买、使用XFAI助手付费服务时，禁止有下列情况发生!'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '3.1 用非法的方式或为了非法的目的使用已购买的XFAI助手学习卡服务;'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '3.2、将您的XFAI助手账户有偿或无偿提供给任何第三人，并允许他通过您的账户使用非他付费购买的XFAI助手学习卡服务。'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '3.3、将XFAI助手学习卡服务复制、销售、出租或授权给任何第三方。'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '3.4、主动对XFAI助手用于保护其XFAI助手学习卡服务的任何安全措施技术进行攻克、反向操作、更改、破坏或其他篡改，或协助他人进行上述行为。'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '3.5、通过非XFAI助手认可的方式获得XFAI助手学习卡服务，或者删险XFAI助手上的任何所有权声明或标签。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '4、您不得通过XFAI助手的任何服务直接或间接地从事以下违反国家法律法规及社会公序良俗之道德保留之行为:'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '1) 违反宪法确定的基本原则的:'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '2) 危害国家安全，泄露国家秘密，颠覆国家政权破坏国家统一的;'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '3) 损害国家莱誉和利益的:'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '4) 煽动民族仇恨、民族歧视:破坏民族团结的;'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '5) 破坏国家宗教攻策:宣扬邪教和封建迷信的;'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '6) 散有谣言，就舌社会秩序，破坏社会稳定的:;'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '7) 散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的;;'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '8) 侮导或者讲谤他人，侵害他人合法权益的;;'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '9) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的;;'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '10) 以非法民间组织名义活动的;'
	},
	{
		c: 'txt indent',
		t: 'p',
		d: '11) 含有法律、行政法规禁止的其他内容的。'
	},
	{
		c: 'txt',
		t: 'p',
		d: 'XFAI助手有权对您就使用服务之行为进行不定期监督，一日发现涉及上述之行为，XFAI助手有机收回您对服务的使用权而不予退还已经支付的费用。而且，XFAI助手将依法向国家机关就您之行为进行举报并保留进一步追究您的行为而导致XFAI助手损失之权利。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '5、为保护您的会员账户安全XFAI助手会员帐号仅限您个人使用，不允许转借或租赁他人。'
	}
]
const note6 = [
	{
		c: 'tit_one',
		t: 'p',
		n: [
			{
				t: 'b',
				d: '六、变更及免责条款'
			}
		]
	},
	{
		c: 'txt',
		t: 'p',
		d: '1、XFAI助手对XFAI助手学习服务的服务使用周期、购买价格保留随时变更的权利，变更本规则在本学习应用上予以公告，并自公告之日起7日后生效。对于因此造成服务的中断或终止而造成的任何损失，除法律有明确规定外，XFAI助手无需对您或任何第三方承担任何责任。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '2、监于网络服务的特殊性，您同意XFAI助手有权随时变更、中所或终止部分或全部的网络服务。XFAI助手保留随时修改或中断服务的权利，并以公告形式通知。对于因此造成服务的中断或终止而造成的任何损失，除法律有明确规定外，XFAI助手无需对您或任何第三方承担任何责任。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '3、对于因服务器的死机、网络的故障、数据库故障、软件升级、服务器维修、调整、升级等问题，或其他不可抗拒的事由，包括但不限于政府行为、自然灾害、黑客攻击等造成的服务中断和对您个人数据及资料造成的损失，除法律有明确规定外，XFAI助手不承担由此对您造成的任何损失或退还任何已缴纳的收费服务费用。但XFAI助手应尽可能事先进行通告。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '4、您购买的XFAI助手学习卡服务使用期限中包含解决故障、服务器维修、调整、升级等所需用的合理时间，对上述情况所需用的时间XFAI助手不予补偿。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '5、XFAI助手对任何问接、偶然、特殊及继起的损害不负责任，不予赔偿。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '6、您在使用XFAI助手所提供的服务时，如遭受任何人身或财务的损失，损害或伤害，除法律有明确规定外，XFAI助手均不负责任，由于您将个人微信密码告知他人或与他人共享登录学习应用，由此导致的任何个人资料泄卖，XFAI助手不负任何责任。'
	}
]
const note7 = [
	{
		c: 'tit_one',
		t: 'p',
		n: [
			{
				t: 'b',
				d: '七、中断及终止服务'
			}
		]
	},
	{
		c: 'txt',
		t: 'p',
		d: '1、如您违反本服务条款中的内容，XFAI助手有权在通知您的情况下立即终止您已购买的所有服务。以及取消您的XFAI助手账户和使用权限，且不退还任何已缴纳的收费服务费用。'
	}
]
const note8 = [
	{
		c: 'tit_one',
		t: 'p',
		n: [
			{
				t: 'b',
				d: '八、隐私权政策'
			}
		]
	},
	{
		c: 'txt',
		t: 'p',
		d: 'XFAI助手非常重视用户个人信息的保护，在您使用XFAI助手提供的服务时，您同意XFAI助手按照在XFAI助手平台上公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。XFAI助手希望通过隐私权政策向您清楚地介绍XFAI助手对您个人信息的处理方式。因此XFAI助手建议您完整地阅读隐私权政策，以帮助您更好地保护您的隐私权。详细个人信息政策请阅《XFAI助手隐私权政策》。如您不同意请不要开通或停止使用本服务。'
	},
	{
		c: 'txt',
		t: 'p',
		d: '本协议最终解释权归XFAI助手所有'
	}
]

export const changchun = [...menu, ...note1, ...note2, ...note3, ...note4, ...note5, ...note6, ...note7, ...note8]
