/*
 * @Author: heyouqin@moyi365.com
 * @LastEditors: heyouqin@moyi365.com
 * @Date: 2022-08-11 10:01:05
 * @LastEditTime: 2022-11-09 16:29:02
 * @Descripttion: file content
 */

import { changchun } from './changchun'
import { bingo } from './bingo'
import { ebook } from './ebook'
import { jmespeak } from './jmespeak'
import { lingyu } from './lingyu'

export default {
	changchun,
	bingo,
	jmespeak,
	ebook,
	lingyu
}
